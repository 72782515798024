export const forms = [
	[
		{
			label: '客户姓名',
			type: 'input',
			attr: 'name',
			placeholder: '请输入客户姓名',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '手机账号',
			type: 'input',
			attr: 'mobile',
			placeholder: '请输入手机账号',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '职业',
			type: 'select',
			attr: 'occupationId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [],
		},
	],
	[
		{
			label: '学历',
			type: 'select',
			attr: 'educationId',
			placeholder: '请输选择',
			column: 8,
			labelWidth: '96px',
			option: [],
		},
		{
			label: '年龄',
			type: 'input',
			attr: 'age',
			inputType: 'number',
			placeholder: '请输入年龄',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '签约时间',
			type: 'date',
			attr: 'contractTime',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
	],
	[
		{
			label: '贷款额度',
			type: 'input',
			inputType: 'number',
			labelWidth: '96px',
			attr: 'loanLimit',
			placeholder: '请输入贷款额度',
			column: 8,
		},
		{
			label: '年收入',
			type: 'input',
			inputType: 'number',
			labelWidth: '96px',
			attr: 'annualIncome',
			placeholder: '请输入年收入',
			column: 8,
		},
	],
	[
		{
			label: '婚姻状态',
			type: 'radio',
			attr: 'marriageStatus',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '已婚', value: 1 },
				{ label: '未婚', value: 0 },
			],
		},
		{
			label: '是否签约',
			type: 'radio',
			attr: 'contractStatus',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '是', value: 1 },
				{ label: '否', value: 0 },
			],
		},
		{
			label: '性别',
			type: 'radio',
			attr: 'sex',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '男', value: 0 },
				{ label: '女', value: 1 },
			],
		},
	],
	[
		{
			label: '房产',
			type: 'radio',
			attr: 'room',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
		{
			label: '公积金',
			type: 'radio',
			attr: 'accumulation',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
		{
			label: '社会保险',
			type: 'radio',
			attr: 'social',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
	],
	[
		{
			label: '车辆',
			type: 'radio',
			attr: 'car',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},

		{
			label: '企业纳税',
			type: 'radio',
			attr: 'enterprise',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
		{
			label: '保单',
			type: 'radio',
			attr: 'policy',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
	],
	[
		{
			label: '营业执照',
			type: 'radio',
			attr: 'business',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
		{
			label: '代发',
			type: 'radio',
			attr: 'issued',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '无', value: 0 },
				{ label: '有', value: 1 },
			],
		},
	],
]
