<template>
  <div class="customer-data">
    <el-tabs
      class="tabs-block"
      v-model="activeName"
    >
      <el-tab-pane
        label="我的客户"
        name="1"
        v-if="buttons.includes('我的客户')"
      >
        <MyCustomer
          :options="options"
          :departments="departments"
          ref="myCustomer"
          @lockCustomer="getLockCustomerList"
          @publicCustomer="getPublicCustomerList"
        />
      </el-tab-pane>

      <el-tab-pane
        label="重要客户"
        name="2"
        v-if="buttons.includes('重要客户')"
      >
        <LockCustomer
          :options="options"
          :departments="departments"
          ref="lockCustomer"
          @myCustomer="getMyCustomerList"
        />
      </el-tab-pane>
      <el-tab-pane
        label="公海客户"
        name="3"
        v-if="buttons.includes('公海客户')"
      >
        <PublicCustomer
          :options="options"
          :departments="departments"
          ref="publicCustomer"
          @myCustomer="getMyCustomerList"
        />
      </el-tab-pane>
      <el-tab-pane
        label="全部客户"
        name="4"
        v-if="buttons.includes('全部客户')"
      >
        <AllCustomer
          :options="options"
          :departments="departments"
          ref="allCustomer"
          @publicPool="getPublicCustomerList"
          @myCustomer="getMyCustomerList"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="customer-btn">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="handleNew"
      >添加客户</el-button>
      <el-button
        type="primary"
        size="small"
        @click="handleRubbish"
      >放入垃圾库</el-button>
    </div>
    <CustomerDialog
      :title="title"
      :isShowDialog="isShowDialog"
      :forms="forms"
      :formData="formData"
      :rules="rules"
      :width="950"
      @submit="handleSubmit"
      @close="handleColse"
    />
  </div>
</template>

<script>
import { ElButton, ElMessage, ElTabPane, ElTabs } from "element-plus";
import { onMounted, reactive, ref } from "vue";
import MyCustomer from "./components/mine/index.vue";
import LockCustomer from "./components/lock/index.vue";
import PublicCustomer from "./components/pulic/index.vue";
import AllCustomer from "./components/all-customer/index.vue";
import { getDictionaries } from "@/api/method/common";
import CustomerDialog from "@/components/dialog-form";
import { forms } from "./index.js";
import { postCustomer, movetoRubbish } from "@/api/method/customer";
import { useStore } from "vuex";
import { validatePhone } from "@/until";

export default {
  name: "CustomerData",
  components: {
    [ElTabs.name]: ElTabs,
    [ElTabPane.name]: ElTabPane,
    [ElButton.name]: ElButton,
    MyCustomer,
    PublicCustomer,
    AllCustomer,
    CustomerDialog,
    LockCustomer,
  },
  setup() {
    const activeName = ref("1");
    const { dispatch, state } = useStore();
    const options = ref(null);
    const buttons = ref(localStorage.getItem("buttons").split(","));
    const dictionaries = async () => {
      const res = await getDictionaries();
      options.value = res.data;
      forms[0][2].option = res.data.occupationList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
      forms[1][0].option = res.data.educationList.map((item) => ({
        label: item.label,
        val: item.vlaue,
      }));
    };
    const departments = ref([]);

    const getDepartList = async () => {
      await dispatch("departmentList");
      departments.value = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };

    onMounted(() => {
      dictionaries();
      getDepartList();
    });
    const formData = reactive({
      name: "",
      age: "",
      mobile: "",
      occupationId: null,
      educationId: null,
      contractTime: null,
      loanLimit: null,
      annualIncome: null,
      marriageStatus: 0,
      contractStatus: 0,
      sex: 0,
      room: 0,
      accumulation: 0,
      social: 0,
      car: 0,
      enterprise: 0,
      policy: 0,
      business: 0,
      issued: 0,
    });
    const rules = reactive({
      name: [{ required: true, message: "请输入用户名称", trigger: "submit" }],
      mobile: [
        {
          required: true,
          validator: (rule, value, callback) => validatePhone(rule, value, callback),
          trigger: "submit",
        },
      ],
      
    });
    const isShowDialog = ref(false);
    const title = ref("添加");
    const handleNew = () => (isShowDialog.value = true);
    const handleColse = () => {
      isShowDialog.value = false;
    };

    const handleSubmit = async () => {
      const res = await postCustomer(formData);
      if (res.code == 200) {
        ElMessage.success("添加成功！");
        isShowDialog.value = false;
      } else {
        ElMessage.error(res.msg);
      }
    };

    const allCustomer = ref(null);
    const myCustomer = ref(null);
    const lockCustomer = ref(null);
    const publicCustomer = ref(null);
    const customerIds = ref([]);
    const handleRubbish = async () => {
      if (activeName.value == 1) {
        customerIds.value = myCustomer.value.customerIds;
      } else if (activeName.value == 2) {
        customerIds.value = lockCustomer.value.customerIds;
      } else if (activeName.value == 3) {
        customerIds.value = publicCustomer.value.customerIds;
      } else if (activeName.value == 4) {
        customerIds.value = allCustomer.value.customerIds;
      }
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }

      const res = await movetoRubbish(customerIds.value);
      if (res.code == 200) {
        getMyCustomerList();
        getLockCustomerList();
        getPublicCustomerList();
        getAllCustomerList();
      }
    };

    const getMyCustomerList = () => myCustomer.value.getList();
    const getLockCustomerList = () => lockCustomer.value.getList();
    const getPublicCustomerList = () => publicCustomer.value.getList();
    const getAllCustomerList = () => allCustomer.value.getList();

    return {
      activeName,
      options,
      isShowDialog,
      title,
      forms,
      formData,
      handleNew,
      handleColse,
      handleSubmit,
      departments,
      handleRubbish,
      myCustomer,
      lockCustomer,
      publicCustomer,
      allCustomer,
      getMyCustomerList,
      getLockCustomerList,
      getPublicCustomerList,
      getAllCustomerList,
      buttons,
      rules
    };
  },
};
</script>

<style lang="scss">
.customer-data {
  position: relative;
  .tabs-block {
    .el-tabs__item.is-active {
      color: #406fff;
    }
    .el-tabs__active-bar {
      background: #406fff;
    }
    .el-tabs__nav-wrap::after {
      border: none;
    }
  }
  .customer-btn {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    .el-button {
      height: 32px;
      margin-left: 10px;
    }
  }
}
</style>